<template>
  <v-breadcrumbs :items="breadcrumbs[$route.name]" large>
    <template v-slot:divider>
      <v-icon>{{ mdiChevronRight }}</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiChevronRight } from '@mdi/js';

export default {
  data: () => ({
    mdiChevronRight,
  }),
  computed: {
    ...mapGetters(['breadcrumbs']),
  },
};
</script>

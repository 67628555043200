<template>
  <v-snackbar
    :value="!!msg"
    rounded="pill"
    top
    right
    transition="slide-y-reverse-transition"
    :color="alertType"
    @input="close"
  >
    {{ msg }}
    <template v-slot:action>
      <v-btn text @click="close">{{ $t('aClose') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields(['msg', 'alertType']),
  },
  methods: {
    close() {
      this.msg = '';
    },
  },
};
</script>

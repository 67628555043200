<template>
  <v-dialog :value="true" width="255" @click:outside="confirm()">
    <v-card>
      <v-card-title class="headline warning">
        <v-icon x-large dark>{{ icons.mdiAlertOutline }}</v-icon>
      </v-card-title>
      <v-card-text class="mt-5">
        <slot/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="confirm()">{{ $t('aClose') }}</v-btn>
        <v-btn text color="error" @click="confirm(true)">
          {{ del ? $t('aDelete') : $t('aConfirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlertOutline } from '@mdi/js';

export default {
  emits: ['confirm-action'],
  props: {
    del: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: { mdiAlertOutline },
  }),
  methods: {
    confirm(value = false) {
      this.$emit('confirm-action', value);
    },
  },
};
</script>
